import React from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'
import { PageLayout, BrandItemPage } from '../../components'
import { DEFAULT_LIST_PAGE } from '../../constants'

const BrandsItemPageTemplate = ({ data, pageContext, location }) => {
  const { lang, brand, products, attributes, attribute_groups, pageIndex } = pageContext

  const navigation = { page: DEFAULT_LIST_PAGE }
  if (pageIndex) {
    navigation.page = pageIndex
  }
  if (typeof window !== 'undefined') {
    const qs = queryString.parse(window.location.search)
    navigation.page = qs.page ? +qs.page : navigation.page
  }

  return (
    <PageLayout lang={lang} switcher={brand}>
      <BrandItemPage
        brand={brand}
        products={products}
        attributes={attributes}
        attribute_groups={attribute_groups}
        lang={lang}
        navigation={navigation} />
    </PageLayout>
  )
}

export default BrandsItemPageTemplate
